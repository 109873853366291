import { useEffect, useState } from "react";
import { BadgeButton, FontWeight } from "../../globalStyles/emotion";
import BriefDetail from "../briefDetails/BriefDetail";
import LocationInformation from "../locationInformation/LocationInformation";
import Map from "../map/Map";
import Payout from "../payout/Payout";
import RideNotes from "../rideNotes/RideNotes";
import RiderHeightWeight from "../riderHeightWeight/RiderHeightWeight";
import EndTime from "../time/EndTime";
import SlashDateFormat from "../time/SlashDateFormat";
import StartTime from "../time/StartTime";
import VehicleDescription from "../vehicleDescription/VehicleDescription";
import "./SidePanelDetails.css";
import { RideDetailsBadges, RideMetaBadges } from "../badges/RideBadges";
import axios from "axios";
import ENV from "../../constants/Environment";
import handleLogout from "../auth/Logout";
import FirstAvailableTooltip from "../firstAvailable/FirstAvailableTooltip";
import PhoneIcon from "@material-ui/icons/Phone";
import { Tooltip } from "@mui/material";
import BestTimeBidBoard from "../bestTimeBidBoard/BestTimeBidBoard";
import { isEmpty } from "lodash";
import { configWithUserId } from "../../axiosHandlers/headerConfigs";

const { apiUrl } = ENV;

// Dispatcher
const SidePanelDetails = ({
  payerName,
  payerType,
  rideNotes,
  id,
  transportTypeId,
  rideType,
  custodianEmail,
  custodianPhone,
  driverDisplayName,
  custodianDisplayName,
  riderDisplayName,
  hasStairs,
  isBariatric,
  startLocationName,
  startLocationAddress,
  startLocationNotes,
  endLocationName,
  endLocationNotes,
  endLocationAddress,
  startLocationLatitude,
  startLocationLongitude,
  endLocationLatitude,
  endLocationLongitude,
  Duration,
  estimatedDistanceMiles,
  estimatedDurationMinutes,
  requestedStartDateDisplay,
  requestedEndTimeDisplay,
  requestedStartTimeDisplay,
  vehicleSummary,
  estimatedPayout,
  bonus,
  riderWeightDisplay,
  actualTotal,
  estimatedTotal,
  riderHeightDisplay,
  oxygenRequired,
  contactPrecautionsRequired,
  riderWillUseProvidersWheelchair,
  riderNotes,
  driverId,
  status,
  firstAvailable,
  startLocationRoomNumber,
  endLocationRoomNumber,
  driverEmail,
  driverPhone,
  riderEmail,
  riderPhone,
  requestedEndDateDisplay,
  bedsideNurseFullName,
  bedsideNursePhone,
  isLongDistance,
  riderDob,
  carePartnerDisplayName,
  carePartnerPhone,
  carePartnerRelationshipType,
  carePartnerRelationshipNotes,
  appointmentTime,
  transportReasonName,
  transportReasonCategoryName,
  transportReasonComment,
  biddingWindowEndsAt = null,
  startLocationTzName,
  bestTimeBids,
  fetchBestTimeBids = () => {},
}) => {
  if (driverDisplayName === undefined) {
    driverDisplayName = "";
  }
  const [mncsAnswers, setMncsAnswers] = useState([]);
  const [locationPhotos, setLocationPhotos] = useState(null);

  const handleFetchAnswers = (id) => {
    axios
      .get(`${apiUrl}/Rides/GetMNCSAnswers?rideId=${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })
      .then((response) => {
        setMncsAnswers(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          handleLogout();
        }
      });
  };

  const handleFetchLocationPhotos = (id) => {
    axios
      .get(`${apiUrl}/Rides/GetRideDetails?rideId=${id}`, configWithUserId)
      .then((response) => {
        setLocationPhotos(response.data);
      });
  };

  ////
  // @example: "Care Parther (roommate)"
  const carePartnerHeader =
    carePartnerRelationshipType === "other"
      ? "Care Partner"
      : `Care Partner (${carePartnerRelationshipType})`;

  useEffect(() => {
    handleFetchAnswers(id);
    handleFetchLocationPhotos(id);

    if (biddingWindowEndsAt !== null) {
      fetchBestTimeBids(id);
    }
    // We need to ignore the `fetchBestTimeBids` dependency because it will cause
    // an infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, biddingWindowEndsAt]);

  return (
    <>
      <div className="teal-borderLeft">
        <div className="displayFlex-badgeIcon">
          <div style={{ maxWidth: "140px" }}>
            <RideDetailsBadges
              id={id}
              rideType={rideType}
              transportTypeId={transportTypeId}
            />
          </div>
          <div
            style={{
              width: "fit-content",
              fontSize: "15px",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <BriefDetail title={"Coord"} name={custodianDisplayName} />
            <div
              style={{
                width: "100%",
                height: "1px",
                background: "lightgrey",
                marginTop: "5px",
                marginBottom: "5px",
              }}
            ></div>
            {localStorage.getItem("Page") === "Dispatch" ? null : (
              <BriefDetail title={"Rider"} name={riderDisplayName} />
            )}{" "}
          </div>

          <div style={{ maxWidth: "150px" }}>
            <RideMetaBadges
              id={id}
              hasStairs={hasStairs}
              isBariatric={isBariatric}
              isLongDistance={isLongDistance}
            />
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: "1px",
            background: "lightgrey",
            marginTop: "10px",
            marginBottom: "5px",
          }}
        ></div>
        <div style={{ display: "flex", gap: "10px" }}>
          <div
            style={{
              display: "flex",
              gap: "10px",
              paddingLeft: "10px",
              minHeight: "250px",
            }}
          >
            <div style={{ paddingBottom: "10px", display: "flex" }}>
              <div style={{ minWidth: "300px", fontSize: "14px" }}>
                <LocationInformation
                  startLocationName={startLocationName}
                  startLocationAddress={startLocationAddress}
                  startLocationNotes={startLocationNotes}
                  startLocationPhoto={locationPhotos?.startLocationPhoto}
                  endLocationName={endLocationName}
                  endLocationAddress={endLocationAddress}
                  endLocationNotes={endLocationNotes}
                  endLocationPhoto={locationPhotos?.endLocationPhoto}
                  rideNotes={rideNotes}
                  startLocationRoomNumber={startLocationRoomNumber}
                  endLocationRoomNumber={endLocationRoomNumber}
                  mncsAnswers={mncsAnswers}
                />
              </div>
              <div style={{ maxHeight: "250px" }}>
                <Map
                  startLocationLatitude={startLocationLatitude}
                  startLocationLongitude={startLocationLongitude}
                  endLocationLatitude={endLocationLatitude}
                  endLocationLongitude={endLocationLongitude}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ background: "lightgrey", height: "1px" }}></div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              borderRight: "1px solid lightgrey",
              fontSize: "14px",
              minWidth: "300px",
              paddingLeft: "10px",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <Duration
              estimatedDurationMinutes={estimatedDurationMinutes}
              estimatedDistanceMiles={estimatedDistanceMiles}
            />

            <div style={{ display: "flex", gap: "5px", fontSize: "14px" }}>
              <p style={{ width: "62px" }}>Start: </p>{" "}
              {firstAvailable && status.toLowerCase() === "pending" ? (
                <FontWeight>First Available</FontWeight>
              ) : (
                <>
                  <SlashDateFormat
                    requestedStartDateDisplay={requestedStartDateDisplay}
                  />{" "}
                  <StartTime
                    requestedStartTimeDisplay={requestedStartTimeDisplay}
                  />
                </>
              )}
              {firstAvailable && <FirstAvailableTooltip status={status} />}
            </div>
            <div style={{ display: "flex", gap: "5px", fontSize: "14px" }}>
              <p style={{ width: "62px" }}>End: </p>{" "}
              {firstAvailable && status.toLowerCase() === "pending" ? (
                <FontWeight>First Available</FontWeight>
              ) : (
                <>
                  <SlashDateFormat
                    requestedStartDateDisplay={requestedStartDateDisplay}
                  />{" "}
                  <EndTime requestedEndTimeDisplay={requestedEndTimeDisplay} />
                </>
              )}
              {firstAvailable && <FirstAvailableTooltip status={status} />}
            </div>
            <div style={{}}>
              <BriefDetail
                title={"Coord"}
                name={custodianDisplayName}
                email={custodianEmail}
                number={custodianPhone}
              />
            </div>
            <div style={{}}>
              {driverDisplayName === undefined ||
              driverDisplayName == null ||
              driverDisplayName.length === 1 ? null : (
                <BriefDetail
                  title={"Driver"}
                  name={driverDisplayName}
                  email={driverEmail}
                  number={driverPhone}
                />
              )}
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ width: "67px" }}>Ride ID: </p>
              <FontWeight>{id}</FontWeight>
            </div>
            {driverId === undefined ||
            driverId === null ||
            driverId === "" ? null : (
              <div style={{ display: "flex" }}>
                <p style={{ width: "67px" }}>Driver ID: </p>
                <FontWeight>{driverId}</FontWeight>
              </div>
            )}
            <div>
              <VehicleDescription vehicleSummary={vehicleSummary} />
            </div>

            <div style={{ display: "flex" }}>
              <p style={{ width: "50px" }}>Status:</p>
              <BadgeButton
                style={{
                  color: "black",
                  borderRadius: "5px",
                  background: "whitesmoke",
                  fontWeight: "600",
                  fontSize: "12px",
                }}
              >
                {status}
              </BadgeButton>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              minWidth: "300px",
              fontSize: "14px",
              marginTop: "10px",
              paddingRight: "10px",
              paddingBottom: "10px",
            }}
          >
            <Payout
              estimatedPayout={estimatedPayout}
              bonus={bonus}
              id={id}
              hasStairs={hasStairs}
              isBariatric={isBariatric}
              transportTypeId={transportTypeId}
              actualTotal={actualTotal}
              estimatedTotal={estimatedTotal}
              rideType={rideType}
            />
            {localStorage.getItem("Page") === "Dispatch" ? null : (
              <BriefDetail
                title={"Rider"}
                name={riderDisplayName}
                email={riderEmail}
                number={riderPhone}
              />
            )}

            {riderDob === undefined || riderDob === null ? null : (
              <div style={{ display: "flex" }}>
                <p style={{ width: "69px" }}>DOB: </p>{" "}
                <SlashDateFormat requestedStartDateDisplay={riderDob} />
              </div>
            )}

            <RiderHeightWeight
              riderWeightDisplay={riderWeightDisplay}
              riderHeightDisplay={riderHeightDisplay}
            />

            {bedsideNurseFullName === null ||
            bedsideNurseFullName === undefined ? null : (
              <div style={{ display: "flex", gap: "5px" }}>
                <p style={{ width: "200px" }}>Bedside Nurse Name:</p>{" "}
                <FontWeight>{bedsideNurseFullName}</FontWeight>{" "}
              </div>
            )}

            {bedsideNursePhone === null ||
            bedsideNursePhone === undefined ? null : (
              <div style={{ display: "flex", gap: "5px" }}>
                <p style={{ width: "200px" }}>Bedside Nurse Phone:</p>{" "}
                <FontWeight>{bedsideNursePhone}</FontWeight>{" "}
              </div>
            )}

            {carePartnerDisplayName === null ||
            carePartnerDisplayName === undefined ? null : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                }}
              >
                <p>{carePartnerHeader}:</p>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    aligmItems: "center",
                  }}
                >
                  <FontWeight style={{ fontSize: "14px", marginRight: "10px" }}>
                    {carePartnerDisplayName}
                  </FontWeight>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "3px",
                    }}
                  >
                    {carePartnerPhone ? (
                      <div>
                        <Tooltip
                          enterDelay={500}
                          title={`Call ${carePartnerDisplayName}`}
                          placement="top"
                        >
                          <a href={`tel:${carePartnerPhone}`}>
                            <PhoneIcon
                              style={{ fontSize: "15px", cursor: "pointer" }}
                            />
                          </a>
                        </Tooltip>
                      </div>
                    ) : null}
                  </div>
                </div>
                {carePartnerRelationshipNotes ? (
                  <FontWeight style={{ fontSize: "14px", marginRight: "10px" }}>
                    {carePartnerRelationshipNotes}
                  </FontWeight>
                ) : null}
              </div>
            )}

            {transportReasonName === null ||
            transportReasonName === undefined ? null : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                }}
              >
                <p>Reason For Transport:</p>
                {transportReasonCategoryName === null ||
                transportReasonCategoryName === undefined ? null : (
                  <div style={{ display: "flex", gap: "5px" }}>
                    <p style={{ width: "69px" }}>Category:</p>{" "}
                    <FontWeight>{transportReasonCategoryName}</FontWeight>{" "}
                  </div>
                )}
                {transportReasonName === null ||
                transportReasonName === undefined ? null : (
                  <div style={{ display: "flex", gap: "5px" }}>
                    <p style={{ width: "69px" }}>Reason:</p>{" "}
                    <FontWeight>{transportReasonName}</FontWeight>{" "}
                  </div>
                )}
                {transportReasonComment === null ||
                transportReasonComment === undefined ? null : (
                  <div style={{ display: "flex", gap: "5px" }}>
                    <p style={{ width: "69px" }}>Comment:</p>{" "}
                    <FontWeight>{transportReasonComment}</FontWeight>{" "}
                  </div>
                )}
              </div>
            )}

            {contactPrecautionsRequired === null ||
            contactPrecautionsRequired === undefined ? null : (
              <div style={{ display: "flex", gap: "5px" }}>
                <p style={{ width: "200px" }}>Droplet Precautions Required:</p>{" "}
                <FontWeight>
                  {contactPrecautionsRequired ? "Yes" : "No"}
                </FontWeight>{" "}
              </div>
            )}

            {oxygenRequired === null || oxygenRequired === undefined ? null : (
              <div style={{ display: "flex", gap: "5px" }}>
                <p style={{ width: "200px" }}>Transport to Provide Oxygen:</p>{" "}
                <FontWeight>{oxygenRequired ? "Yes" : "No"}</FontWeight>{" "}
              </div>
            )}

            {
              // Only show if the transport type is "Wheelchair".
              transportTypeId === 4 && (
                <div style={{ display: "flex", gap: "5px" }}>
                  <p style={{ width: "80px" }}>Wheelchair:</p>{" "}
                  <FontWeight>
                    {riderWillUseProvidersWheelchair
                      ? "Rider will use Transport Provider's"
                      : "Rider has their own Wheelchair"}
                  </FontWeight>{" "}
                </div>
              )
            }

            {requestedEndDateDisplay === undefined ? null : (
              <div style={{ display: "flex" }}>
                <p style={{ width: "69px" }}>Complete: </p>{" "}
                <SlashDateFormat
                  requestedStartDateDisplay={requestedEndDateDisplay}
                />
              </div>
            )}
            {rideNotes == null || rideNotes.length === 0 ? null : (
              <div style={{ display: "flex" }}>
                <p style={{ width: "66px" }}> Notes: </p>
                <div style={{ maxWidth: "300px" }}>
                  {" "}
                  <RideNotes
                    rideNotes={riderNotes}
                    startLocationRoomNumber={startLocationRoomNumber}
                    endLocationRoomNumber={endLocationRoomNumber}
                  />
                </div>
              </div>
            )}
            {payerType === null ? null : (
              <div style={{ display: "flex", gap: "5px" }}>
                <p style={{ width: "62px" }}>Paid by:</p>{" "}
                <FontWeight>{payerType} </FontWeight>{" "}
              </div>
            )}
            {payerName === null ? null : (
              <BriefDetail title={"Payer"} name={payerName} />
            )}
          </div>
        </div>{" "}
        {!isEmpty(bestTimeBids) && biddingWindowEndsAt !== null ? (
          <BestTimeBidBoard
            requestedStartTime={requestedStartTimeDisplay}
            biddingWindowEndsAt={biddingWindowEndsAt}
            startLocationTzName={startLocationTzName}
            bestTimeBids={bestTimeBids}
          />
        ) : null}
        <div style={{ background: "lightgrey", height: "1px" }}></div>
      </div>
    </>
  );
};

export default SidePanelDetails;
